/* eslint-disable import/no-anonymous-default-export */
export default {
  
  Staking: "0xb6E9E76D2AF8546F30017b851FdFfeA0B21D4cDd",
  NFT: "0xEFbb6F4555Df8f2c0f164Cf2810B55f3866B484D",
  USDT:"0x55d398326f99059fF775485246999027B3197955"
  
};



