import React, { useEffect, useState, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import abis from "../constants/abi";
import contracts from "../constants/contracts";
import getRpcUrl from "../utils/getRpcUrl";
import useToast from "../hooks/useToast";

const Vault = () => {
  const { account, active } = useWeb3React();
  const { toastSuccess, toastError } = useToast();
  const web3 = new Web3(Web3.givenProvider || getRpcUrl());

  const [nfts, setNfts] = useState([]);
  const [availableNfts, setAvailableNfts] = useState([]);
  const [selectedNft, setSelectedNft] = useState("");
  const [totalRewards, setTotalRewards] = useState(0);
  const [withdrawTimers, setWithdrawTimers] = useState({});
  const timerRefs = useRef({});
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalValueDeposited, setTotalValueDeposited] = useState(0);
  const [totalGeneratedRewards, setTotalGeneratedRewards] = useState(0);
  const [claimTimer, setClaimTimer] = useState("");
  const stakingContract = new web3.eth.Contract(abis.Staking, contracts.Staking);
  const [refresh, setRefresh] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Detect screen size to determine if mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch NFTs, total deposited value, and rewards
  const fetchNFTs = async () => {
    try {
      const nftContract = new web3.eth.Contract(abis.NFT, contracts.NFT);
      const stakedNFTs = await stakingContract.methods.getNftOwnedByUser(account).call();
      const totalInWallet = await nftContract.methods.balanceOf(account).call();

      const totalStaked = await stakingContract.methods.getTotalNftsInVault().call();
      const totalDeposited = await stakingContract.methods.totalValueDeposited().call();
      const totalRewardsGenerated = await stakingContract.methods.totalRewardsGenerated().call();

      setTotalStaked(totalStaked);
      setTotalValueDeposited(totalDeposited);
      setTotalGeneratedRewards(totalRewardsGenerated);

      const items = [];
      const availableItems = [];
      let totalRewardsSum = 0;

      for (let i = 0; i < stakedNFTs.length; i++) {
        const tokenId = stakedNFTs[i];
        const pendingRewards = await stakingContract.methods.pendingRewards(tokenId).call();
        const nftData = await stakingContract.methods.stakes(tokenId).call();
        totalRewardsSum += pendingRewards;

        items.push({
          tokenId,
          dividendsAvailable: pendingRewards,
          usdtDeposited: nftData.amount,
        });
      }

      for (let i = 0; i < totalInWallet; i++) {
        const tokenId = await nftContract.methods.tokenOfOwnerByIndex(account, i).call();
        const isStaked = items.some((item) => item.tokenId === tokenId);
        if (!isStaked) {
          availableItems.push({ tokenId });
        }
      }

      setNfts(items);
      setAvailableNfts(availableItems);
      setTotalRewards(totalRewardsSum);
    } catch (error) {
      toastError("Failed to fetch NFT data.");
    }
  };

  useEffect(() => {
    if (account) {
      fetchNFTs();
    }
    return () => {
      Object.values(timerRefs.current).forEach((interval) => clearInterval(interval));
    };
  }, [account]);

  const handleApproveAndStake = async () => {
    if (!selectedNft) {
      toastError("Please select an NFT to stake.");
      return;
    }

    try {
      const nftContract = new web3.eth.Contract(abis.NFT, contracts.NFT);
      const stakingContractAddress = contracts.Staking;
      await nftContract.methods.approve(stakingContractAddress, selectedNft).send({ from: account });
      await stakingContract.methods.stakeNFT(selectedNft).send({ from: account });
      toastSuccess("NFT staked successfully!");
      fetchNFTs();
    } catch (error) {
      toastError("Approval or Staking failed!");
    }
  };

  return (
    <div style={styles.container(isMobile)}>
      <h2 style={styles.title(isMobile)}>My NFT Vault</h2>
      <div>
        <div style={styles.rowStyle(isMobile)}>
          <div style={styles.cellStyle(isMobile)}>Total Value Deposited</div>
          <div style={styles.cellStyle(isMobile)}>Total Rewards Generated</div>
          <div style={styles.cellStyle(isMobile)}>Total NFT Staked</div>
          <div style={styles.cellStyle(isMobile)}>DCER APR/APY</div>
        </div>
        <div style={styles.rowStyle(isMobile)}>
          <div style={styles.cellValueStyle(isMobile)}>{totalValueDeposited / 10 ** 18} USDT</div>
          <div style={styles.cellValueStyle(isMobile)}>{totalGeneratedRewards / 10 ** 18} USDT</div>
          <div style={styles.cellValueStyle(isMobile)}>{totalStaked}</div>
          <div style={styles.cellValueStyle(isMobile)}>120%</div>
        </div>
      </div>
      <div style={styles.imageContainerStyle(isMobile)}>
        <img src="/images/NXnG22.tif@2x-8.png" alt="Vault" style={styles.imageStyle(isMobile)} />
      </div>
      <div style={styles.stakeContainer(isMobile)}>
        <h3>Stake an NFT</h3>
        <div style={styles.inputGroup(isMobile)}>
          <label>Select NFT: </label>
          <select value={selectedNft} onChange={(e) => setSelectedNft(e.target.value)} style={styles.dropdown(isMobile)}>
            <option value="" disabled>
              Select an NFT
            </option>
            {availableNfts.map((nft, index) => (
              <option key={index} value={nft.tokenId}>
                Token ID: {nft.tokenId}
              </option>
            ))}
          </select>
        </div>
        <button style={styles.stakeButton(isMobile)} onClick={handleApproveAndStake} disabled={!selectedNft}>
          Vault NFT
        </button>
      </div>
      <div style={styles.imageContainerStyle(isMobile)}>
        <img src="/images/XTr2Qw.tif@2x-8.png" alt="Vault" style={styles.imageStyle(isMobile)} />
      </div>
      <div style={styles.tableContainer(isMobile)}>
        <div style={styles.tableHeader(isMobile)}>
          <div style={styles.headerItem(isMobile)}>Token ID</div>
          <div style={styles.headerItem(isMobile)}>USDT Deposited</div>
          <div style={styles.headerItem(isMobile)}>Available Rewards</div>
          <div style={styles.headerItem(isMobile)}>Withdraw Timer</div>
          <div style={styles.headerItem(isMobile)}>Actions</div>
        </div>
        {nfts.map((nft, index) => (
          <div key={index} style={styles.tableRow(isMobile)}>
            <div style={styles.rowItem(isMobile)}>{nft.tokenId}</div>
            <div style={styles.rowItem(isMobile)}>{nft.usdtDeposited / 10 ** 18} USDT</div>
            <div style={styles.rowItem(isMobile)}>{nft.dividendsAvailable / 10 ** 18} USDT</div>
            <div style={styles.rowItem(isMobile)}>00:00:00</div>
            <div style={styles.actionItems(isMobile)}>
              <button style={styles.button(isMobile)}>Claim</button>
              <button style={styles.button(isMobile)}>Withdraw</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vault;
const styles = {
  container: (isMobile) => ({
    padding: isMobile ? "10px" : "20px",
    backgroundColor: "#1f1f2e",
    borderRadius: "12px",
    color: "white",
    width: "100%",
    maxWidth: isMobile ? "100%" : "1000px",
    margin: "0 auto",
  }),
  title: (isMobile) => ({
    fontSize: isMobile ? "20px" : "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#f1c40f",
    textAlign: "center",
  }),
  stakeContainer: (isMobile) => ({
    marginBottom: "30px",
    padding: isMobile ? "10px" : "20px",
    backgroundColor: "#2c2c3a",
    borderRadius: "12px",
  }),
  inputGroup: (isMobile) => ({
    marginBottom: "10px",
  }),
  dropdown: (isMobile) => ({
    width: "100%",
    padding: isMobile ? "5px" : "8px",
    borderRadius: "5px",
    border: "1px solid #444",
    backgroundColor: "#1f1f2e",
    color: "white",
  }),
  stakeButton: (isMobile) => ({
    backgroundColor: "#8e44ad",
    color: "white",
    padding: isMobile ? "8px 15px" : "10px 20px",
    border: "1px solid #f1c40f",
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    width: "100%",
  }),
  rowStyle: (isMobile) => ({
    display: isMobile ? "block" : "flex", // Change to block layout for mobile
    justifyContent: "space-between",
    marginBottom: "10px",
    flexWrap: "wrap",
    alignItems: "center",
  }),
  cellStyle: (isMobile) => ({
    flex: 1,
    fontWeight: "bold",
    fontSize: isMobile ? "12px" : "16px",
    textAlign: "center",
    color: "#b0b0b0",
    margin: "5px 0",
    padding: "5px", // Added padding for spacing
  }),
  cellValueStyle: (isMobile) => ({
    flex: 1,
    fontSize: isMobile ? "12px" : "18px",
    textAlign: "center",
    color: "white",
    margin: "5px 0",
    padding: "5px",
  }),
  imageContainerStyle: (isMobile) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  }),
  imageStyle: (isMobile) => ({
    width: "100%",
    maxWidth: "400px",
    height: "auto",
    borderRadius: "12px",
    border: "1px solid #444",
  }),
  tableContainer: (isMobile) => ({
    width: "100%",
    marginBottom: "20px",
    backgroundColor: "#2c2c3a",
    borderRadius: "12px",
    padding: "10px", // Reduced padding on mobile
    overflowX: "auto",
  }),
  tableHeader: (isMobile) => ({
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #444",
    paddingBottom: "10px",
    marginBottom: "10px",
    flexWrap: "wrap",
  }),
  headerItem: (isMobile) => ({
    flex: "1 1 100px",
    fontWeight: "bold",
    fontSize: isMobile ? "12px" : "16px", // Smaller font for mobile
    textAlign: "center",
    color: "#b0b0b0",
    padding: isMobile ? "5px" : "10px", // Added padding
  }),
  tableRow: (isMobile) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    flexWrap: "wrap",
    borderBottom: isMobile ? "1px solid #444" : "none", // Divider between rows on mobile
    paddingBottom: isMobile ? "10px" : "0", // Extra padding for rows on mobile
  }),
  rowItem: (isMobile) => ({
    flex: "1 1 100px",
    textAlign: "center",
    fontSize: isMobile ? "12px" : "18px",
    color: "white",
    margin: "5px 0",
    padding: isMobile ? "5px" : "10px", // Added padding for spacing
    whiteSpace: "nowrap", // Prevent wrapping
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  actionItems: (isMobile) => ({
    flex: "1 1 100px",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: isMobile ? "10px" : "0", // Ensure buttons are spaced out on mobile
  }),
  button: (isMobile) => ({
    backgroundColor: "#8e44ad",
    color: "white",
    padding: isMobile ? "5px 10px" : "10px 15px",
    border: "1px solid #f1c40f",
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    width: isMobile ? "80px" : "auto", // Adjust button width on mobile
    fontSize: isMobile ? "12px" : "14px", // Smaller font for buttons on mobile
  }),
};
