import { toast } from "react-toastify";

const useToast = () => {
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "bottom-left",
    });
  };

  const toastError = (message) => {
    toast.error(message, {
      position: "bottom-left",
    });
  };

  const toastWarning = (message) => {
    toast.warn(message, {
      position: "bottom-left",
    });
  };

  const toastInfo = (message) => {
    toast.info(message, {
      position: "bottom-left",
    });
  };

  return { toastSuccess, toastError, toastWarning, toastInfo };
};

export default useToast;
