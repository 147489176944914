import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

const Footer: React.FC = () => {
  const { account } = useWeb3React();
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <footer
      style={{
        backgroundColor: "#1f1f2e",
        color: "white",
        padding: "20px 0", // Reduced padding for a more compact look
      }}
    >
      <div
        style={{
          backgroundColor: "#2c2c3a",
          padding: "20px 0", // Reduced padding for a more compact look
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "0 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <img
              src="/images/Asset 19@2x-8.png" // Ensure the path is correct
              alt="iLendCapital"
              style={{
                height: "100px", // Adjusted to better fit the header with navigation
                cursor: "pointer",
              }}
            />
            <div
              style={{
                flex: "1 1 200px", // Reduced width for a more compact look
                marginBottom: "20px",
              }}
            >
              <p
                style={{
                  fontSize: "14px", // Adjusted the font size to match the compact design
                  marginBottom: "10px", // Reduced the margin for a more compact layout
                  color: "#b0b0b0", // Light gray color to match the rest of the footer text
                }}
              >
                While iLend is a Yield Bearing NFT asset, profits are not guaranteed and should not be treated or looked at as an investment.
              </p>
              <p
                style={{
                  fontSize: "12px", // Smaller font size for the copyright text
                  color: "#b0b0b0",
                  flex: "1", // Takes up available space
                  textAlign: "left", // Aligns to the left
                }}
              >
                iLend Capital 2023 - ALL rights reserved
              </p>
            </div>
            <div
              style={{
                flex: "2 1 300px",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div style={{ flex: "1" }}>
                <h6
                  style={{
                    fontSize: "16px", // Slightly smaller font size for titles
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "#f1c40f",
                  }}
                >
                  SITE LINKS
                </h6>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <li style={{ marginBottom: "8px" }}>
                    <Link
                      to="/"
                      style={{
                        color: "white", // Ensure white color for links
                        textDecoration: "none",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.color = "#f1c40f")}
                      onMouseLeave={(e) => (e.currentTarget.style.color = "white")}
                    >
                      Home
                    </Link>
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <Link
                      to="/vault"
                      style={{
                        color: "white", // Ensure white color for links
                        textDecoration: "none",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.color = "#f1c40f")}
                      onMouseLeave={(e) => (e.currentTarget.style.color = "white")}
                    >
                      Vault
                    </Link>
                  </li>
                </ul>
              </div>
              <div style={{ flex: "1" }}>
                <h6
                  style={{
                    fontSize: "16px", // Slightly smaller font size for titles
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "#f1c40f",
                  }}
                >
                  USEFUL LINKS
                </h6>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <li style={{ marginBottom: "8px" }}>
                    <Link
                      to="https://bscscan.com/"
                      style={{
                        color: "white", // Ensure white color for links
                        textDecoration: "none",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.color = "#f1c40f")}
                      onMouseLeave={(e) => (e.currentTarget.style.color = "white")}
                    >
                      NFT contract
                    </Link>
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <Link
                      to="https://bscscan.com/"
                      style={{
                        color: "white", // Ensure white color for links
                        textDecoration: "none",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.color = "#f1c40f")}
                      onMouseLeave={(e) => (e.currentTarget.style.color = "white")}
                    >
                      Staking contract
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVisible && (
        <div
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#f1c40f",
            color: "#1f1f2e",
            padding: "10px",
            borderRadius: "50%",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          ↑
        </div>
      )}
    </footer>
  );
};

export default Footer;
