import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Vault from "../pages/Vault";

const Main: React.FC = () => {
  return (
    <main
      style={{
        flex: 1,
        padding: "20px",
        backgroundColor: "#282828",
        color: "white",
        minHeight: "100vh",
        boxSizing: "border-box",
      }}
    >
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/vault" element={<Vault />} />
        <Route path="*" element={<Navigate to="/" />} /> {/* Redirect unknown routes to Dashboard */}
      </Routes>
    </main>
  );
};

export default Main;
