import sample from "lodash/sample";

export const CHAIN_ID = 56;
export const NODES = {
  56: ["https://virtual.binance.rpc.tenderly.co/08a409a3-4e8e-4230-8b02-d9fd21713360"],
  // 56: ["https://binance.llamarpc.com	"],
};

const getNodeUrl = () => {
  const nodeUrl = sample(NODES[CHAIN_ID]);
  if (nodeUrl === undefined) {
    return NODES[CHAIN_ID][0];
  }
  return nodeUrl;
};

export default getNodeUrl;
